import Cookies from 'js-cookie';
import { DEFAULT_LANGUAGE, languages } from '../locales/i18n';

export const expires = 365 * 10;
export const saveTokenCookie = (token?: string, expires?: number) => {
  if (token) {
    Cookies.set('access_token', token, { expires });
  }
};

export const getTokenFromCookie = () => {
  return Cookies.get('access_token');
};

export const saveRefreshTokenCookie = (refresh_token?: string, expires?: number) => {
  if (refresh_token) {
    Cookies.set('refresh_token', refresh_token, { expires });
  }
};

export const getRefreshTokenFromCookie = () => {
  const refresh_token = Cookies.get('refresh_token');
  return refresh_token ? refresh_token : '';
};

export const saveUserCookie = (data_user: any, expires?: number) => {
  const { accessToken, refreshToken, ...dataUserWithoutTokens } = data_user;
  Cookies.set('data_user', JSON.stringify(dataUserWithoutTokens), { expires });
};

export const saveUserToChatCookie = (data_user: any, expires?: number) => {
  Cookies.set('data_user_chat', JSON.stringify(data_user), { expires });
};

export const saveConsultantCookie = (consultant: any, expires?: number) => {
  Cookies.set('consultant', JSON.stringify(consultant), { expires });
};

export const getUserFromCookie = (): any => {
  let data = {};
  if (Cookies.get('data_user')) {
    try {
      const userLocal = Cookies.get('data_user');
      data = JSON.parse(userLocal ? userLocal : '');
    } catch (e) {
      data = {};
    }
  }
  return data;
};

export const saveClientUserCookie = (client: any, expires?: number) => {
  Cookies.set('clientUser', JSON.stringify(client), { expires });
};

export const saveEmployeeWebCookie = (employee: any, expires?: number) => {
  Cookies.set('employeeWebUser', JSON.stringify(employee), { expires });
};

export const getConsultant = () => {
  return Cookies.get('consultant') ?? '';
};

export const removeRefreshToken = () => {
  return Cookies.remove('refresh_token');
};

export const removeToken = () => {
  return Cookies.remove('access_token');
};

export const removeUser = () => {
  Cookies.remove('data_user');
};

export const getClientUser = () => {
  return Cookies.get('clientUser') ?? '';
};

// Current User LocalStorage
export const getUserToChat = (): any => {
  let data: any = {};
  try {
    const userLocal = Cookies.get('data_user_chat');
    data = JSON.parse(userLocal ? userLocal : '');
  } catch (e) {
    data = {};
  }
  return data;
};

export const getLanguage = () => {
  const language = localStorage.getItem('i18nextLng');
  return language && languages.includes(language) ? language : DEFAULT_LANGUAGE;
};

export const saveLanguage = (language: string) => {
  if (languages.includes(language)) {
    localStorage.setItem('i18nextLng', language);
  } else {
    localStorage.setItem('i18nextLng', DEFAULT_LANGUAGE);
  }
};
