import { axiosClient } from './axiosClient';
import {
  TClientManagement,
  TFacilityManagement,
  TGroupManagement,
} from '../types/clientManagement/clientManagement';
import { TOptionsQuery } from '../types/common';
import queryString from 'querystring';

export const clientApi = {
  getListClientManagementByFacility: (id: string) => {
    const url = `/employee-progress/getByFacility/${id}`;
    return axiosClient.get(url);
  },
  getListClientManagement: (params: TOptionsQuery<TClientManagement>) => {
    const url = '/client';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  registerClient: (data: TClientManagement) => {
    const url = '/client';
    return axiosClient.post(url, data);
  },
  updateClient: (params: { id: string; data: TClientManagement }) => {
    const url = `/client/${params.id}`;
    return axiosClient.put(url, params.data);
  },
  deleteClient: (id: string) => {
    const url = `/client/${id}`;
    return axiosClient.delete(url);
  },
  inActiveClient: (id: string) => {
    const url = `/client/${id}/inactive`;
    return axiosClient.post(url);
  },
  activeClient: (id: string) => {
    const url = `/client/${id}/active`;
    return axiosClient.post(url);
  },
  getListFacilityManagement: (params: TOptionsQuery<TFacilityManagement>) => {
    const url = '/facility';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  registerFacility: (data: TFacilityManagement) => {
    const url = '/facility';
    return axiosClient.post(url, data);
  },
  updateFacility: (params: { id: string; data: TFacilityManagement }) => {
    const url = `/facility/${params.id}`;
    return axiosClient.put(url, params.data);
  },
  inActiveFacility: (id: string) => {
    const url = `/facility/${id}/inactive`;
    return axiosClient.post(url);
  },
  activeFacility: (id: string) => {
    const url = `/facility/${id}/active`;
    return axiosClient.post(url);
  },
  deleteFacility: (id: string) => {
    const url = `/facility/${id}`;
    return axiosClient.delete(url);
  },
  getListGroupManagement: (params: TOptionsQuery<TGroupManagement>) => {
    const url = '/group';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  registerGroup: (data: TGroupManagement) => {
    const url = '/group';
    return axiosClient.post(url, data);
  },
  updateGroup: (params: { id: string; data: TGroupManagement }) => {
    const url = `/group/${params.id}`;
    return axiosClient.put(url, params.data);
  },
  deleteGroup: (id: string) => {
    const url = `/group/${id}`;
    return axiosClient.delete(url);
  },
};
