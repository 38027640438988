import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clientApi } from '../../api/clientApi';
import {
  TClientManagement,
  TFacilityManagement,
  TGroupManagement,
} from '../../types/clientManagement/clientManagement';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE, TActionParams, TOptionsQuery } from '../../types/common';
import { setShowToast } from '../toast/toastSlice';
import { ETypeToast } from '../../app/enum';
import { TSetListAction } from 'types/types';
import { getClientUser } from '../../helpers/cookies';
import { setListImplementByFacility } from '../implements/implementsSlice';
import { t } from 'i18next';
import { translations } from 'locales/translations';
import { convertValueToNumber, getLabelError } from '../../helpers/funcs';
import { getUserFromCookie } from '../../helpers/cookies';
import { statusErrEmail } from '../../helpers/constant';

export type ClientState = {
  loading: boolean;
  listClientManagement: Array<TClientManagement>;
  listFacilityManagement: Array<TFacilityManagement>;
  listGroupManagement: Array<TGroupManagement>;
  total: number;
  totalPage: number;
  page: number;
  totalFacility: number;
  pageFacility: number;
  totalPageFacility: number;
  totalGroup: number;
  pageGroup: number;
  totalPageGroup: number;
  clientSelected: TClientManagement | null;
  emailErr: string;
};

const initialState: ClientState = {
  loading: false,
  listClientManagement: [],
  listFacilityManagement: [],
  listGroupManagement: [],
  total: 0,
  totalPage: 1,
  page: 1,
  totalFacility: 0,
  totalPageFacility: 1,
  pageFacility: 1,
  totalGroup: 0,
  totalPageGroup: 1,
  pageGroup: 1,
  clientSelected: null,
  emailErr: '',
};

export const DEFAULT_ORDER_BY_CLIENT_MANAGEMENT = 'createdAt';
export const DEFAULT_ORDER_DIRECTION_CLIENT_MANAGEMENT = 'desc';
export const DEFAULT_ORDER_DIRECTION_GROUP_MANAGEMENT = 'asc';

export const getListClientManagementByFacility = createAsyncThunk(
  'client/getListClientManagement',
  async (facilityId: string, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await clientApi
      .getListClientManagementByFacility(facilityId)
      .then((response) => {
        thunkAPI.dispatch(setListImplementByFacility(response.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getListClientManagement = createAsyncThunk(
  'client/getListClientManagement',
  async (params: TOptionsQuery<TClientManagement>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TClientManagement> = {
      ...rest,
      search: rest.search ?? '',
      orderBy: rest.orderBy ?? DEFAULT_ORDER_BY_CLIENT_MANAGEMENT,
      orderDirection: rest.orderDirection ?? DEFAULT_ORDER_DIRECTION_CLIENT_MANAGEMENT,
      page: rest.page ?? DEFAULT_PAGE,
      perPage: 1000,
      isFilter: rest?.isFilter ? true : false,
    };
    await clientApi
      .getListClientManagement(newParams)
      .then((response) => {
        thunkAPI.dispatch(
          setListClientManagement({
            type: loadMore ? 'loadMore' : 'getList',
            clients: response.data.data,
            total: response.data._metadata.pagination.total,
            totalPage: response.data._metadata.pagination.totalPage,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const registerClientManagement = createAsyncThunk(
  'client/registerClientManagement',
  async (
    params: { actionParams: TActionParams<TClientManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await clientApi
      .registerClient(params.actionParams.data)
      .then(() => {
        params.handleClose();
        thunkAPI.dispatch(getListClientManagement({ isFilter: false, perPage: 1000 }));
        thunkAPI.dispatch(setPage(1));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.REGISTER_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr(getLabelError(response.response.data.statusCode)));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateClientManagement = createAsyncThunk(
  'client/updateClientManagement',
  async (
    params: { id: string; data: TActionParams<TClientManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await clientApi
      .updateClient({ id: params.id, data: params.data.data })
      .then((response: any) => {
        thunkAPI.dispatch(
          setListClientManagement({
            type: 'edit',
            client: { ...params.data.data, _id: response.data.data._id },
          }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        params.handleClose();
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr('このメールアドレスが使用されています。'));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteClientManagement = createAsyncThunk(
  'client/deleteClientManagement',
  async (client: TClientManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await clientApi
      .deleteClient(String(client._id))
      .then(() => {
        thunkAPI.dispatch(setListClientManagement({ type: 'delete', client: client }));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const stopClientManagement = createAsyncThunk(
  'client/stopClientManagement',
  async (client: TClientManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    if (client.isActive) {
      await clientApi
        .inActiveClient(String(client._id))
        .then(() => {
          thunkAPI.dispatch(setListClientManagement({ type: 'stop', client: client }));
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.INACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        });
    } else {
      await clientApi
        .activeClient(String(client._id))
        .then(() => {
          thunkAPI.dispatch(setListClientManagement({ type: 'stop', client: client }));
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.ACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        });
    }
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getListFacilityManagement = createAsyncThunk(
  'client/getListFacilityManagement',
  async (params: TOptionsQuery<TFacilityManagement>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TFacilityManagement> = {
      ...rest,
      search: rest.search ?? '',
      orderBy: rest.orderBy ?? DEFAULT_ORDER_BY_CLIENT_MANAGEMENT,
      orderDirection: rest.orderDirection ?? DEFAULT_ORDER_DIRECTION_CLIENT_MANAGEMENT,
      page: rest.page ?? DEFAULT_PAGE,
      perPage: rest.perPage ?? DEFAULT_PER_PAGE,
      clientId: params.clientId,
      isFilter: params.isFilter,
    };
    await clientApi
      .getListFacilityManagement(newParams)
      .then((response) => {
        thunkAPI.dispatch(
          setListFacilityManagement({
            type: loadMore ? 'loadMore' : 'getList',
            facilities: response.data.data.map((item) => ({
              ...item,
              key: item._id,
            })),
            total: response.data._metadata.pagination.total,
            page: response.data._metadata.pagination.page,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const registerFacilityManagement = createAsyncThunk(
  'client/registerFacilityManagement',
  async (
    params: { actionParams: TActionParams<TFacilityManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await clientApi
      .registerFacility(params.actionParams.data)
      .then(() => {
        params.handleClose();
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.REGISTER_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        thunkAPI.dispatch(
          getListFacilityManagement({ perPage: 0, clientId: params.actionParams.data.clientId }),
        );
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr('このメールアドレスが使用されています。'));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateFacilityManagement = createAsyncThunk(
  'client/updateFacilityManagement',
  async (
    params: { id: string; data: TActionParams<TFacilityManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await clientApi
      .updateFacility({ id: params.id, data: params.data.data })
      .then((response: any) => {
        thunkAPI.dispatch(
          setListFacilityManagement({
            type: 'edit',
            facility: { ...params.data.data, _id: response.data.data._id },
          }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        params.handleClose();
      })
      .catch((response) => {
        if (statusErrEmail.includes(response.response.data.statusCode)) {
          thunkAPI.dispatch(setEmailErr('このメールアドレスが使用されています。'));
        } else {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
        }
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const stopFacilityManagement = createAsyncThunk(
  'client/stopClientManagement',
  async (facility: TFacilityManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    if (facility.isActive) {
      await clientApi
        .inActiveFacility(String(facility._id))
        .then(() => {
          thunkAPI.dispatch(setListFacilityManagement({ type: 'stop', facility: facility }));
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.INACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        });
    } else {
      await clientApi
        .activeFacility(String(facility._id))
        .then(() => {
          thunkAPI.dispatch(setListFacilityManagement({ type: 'stop', facility: facility }));
          thunkAPI.dispatch(
            setShowToast({
              label: t(translations.ID_MASTER.ACTIVE_SUCCESS),
              type: ETypeToast.Success,
            }),
          );
        })
        .catch((response) => {
          thunkAPI.dispatch(
            setShowToast({
              label: getLabelError(response.response.data.statusCode),
              type: ETypeToast.Error,
            }),
          );
          thunkAPI.dispatch(setLoading(false));
        });
    }
    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteFacilityManagement = createAsyncThunk(
  'client/deleteClientManagement',
  async (facility: TFacilityManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await clientApi
      .deleteFacility(String(facility._id))
      .then(() => {
        thunkAPI.dispatch(setListFacilityManagement({ type: 'delete', facility: facility }));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getListGroupManagement = createAsyncThunk(
  'client/getListGroupManagement',
  async (params: TOptionsQuery<TGroupManagement>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TGroupManagement> = {
      ...rest,
      search: rest.search ?? '',
      orderBy: rest.orderBy ?? DEFAULT_ORDER_BY_CLIENT_MANAGEMENT,
      orderDirection: rest.orderDirection ?? DEFAULT_ORDER_DIRECTION_GROUP_MANAGEMENT,
      page: rest.page ?? DEFAULT_PAGE,
      perPage: rest.perPage ?? DEFAULT_PER_PAGE,
      clientId: params.clientId,
      isFilter: params.isFilter,
    };
    await clientApi
      .getListGroupManagement(newParams)
      .then((response) => {
        thunkAPI.dispatch(
          setListGroupManagement({
            type: loadMore ? 'loadMore' : 'getList',
            groups: response.data.data.map((item) => ({
              ...item,
              key: item._id,
            })),
            total: response.data._metadata.pagination.total,
            page: response.data._metadata.pagination.page,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const registerGroupManagement = createAsyncThunk(
  'client/registerGroupManagement',
  async (
    params: { actionParams: TActionParams<TGroupManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await clientApi
      .registerGroup(params.actionParams.data)
      .then(() => {
        params.handleClose();
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.REGISTER_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        thunkAPI.dispatch(
          getListGroupManagement({ perPage: 0, clientId: params.actionParams.data.clientId }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateGroupManagement = createAsyncThunk(
  'client/updateGroupManagement',
  async (
    params: { id: string; data: TActionParams<TGroupManagement>; handleClose: () => void },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await clientApi
      .updateGroup({ id: params.id, data: params.data.data })
      .then((response: any) => {
        thunkAPI.dispatch(
          setListGroupManagement({
            type: 'edit',
            group: { ...params.data.data, _id: response.data.data._id },
          }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        params.handleClose();
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const stopGroupManagement = createAsyncThunk(
  'client/stopGroupManagement',
  async (group: TGroupManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));

    await clientApi
      .updateGroup({ id: group._id ?? '', data: group })
      .then(() => {
        thunkAPI.dispatch(setListGroupManagement({ type: 'stop', group: group }));
        thunkAPI.dispatch(
          group.isActive
            ? setShowToast({
                label: t(translations.ID_MASTER.ACTIVE_SUCCESS),
                type: ETypeToast.Success,
              })
            : setShowToast({
                label: t(translations.ID_MASTER.ACTIVE_SUCCESS),
                type: ETypeToast.Success,
              }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });

    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteGroupManagement = createAsyncThunk(
  'client/deleteGroupManagement',
  async (group: TGroupManagement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await clientApi
      .deleteGroup(String(group._id))
      .then(() => {
        thunkAPI.dispatch(setListGroupManagement({ type: 'delete', group: group }));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setListClientManagement: (
      state,
      action: PayloadAction<{
        type: TSetListAction;
        client?: TClientManagement;
        clients?: TClientManagement[];
        totalPage?: number;
        total?: number;
      }>,
    ) => {
      const { type, client, clients, total, totalPage } = action.payload;
      if (client) {
        switch (type) {
          case 'create':
            state.listClientManagement = [
              { ...client, isActive: true },
              ...state.listClientManagement,
            ];
            break;
          case 'edit':
            state.listClientManagement = state.listClientManagement.map((c: TClientManagement) =>
              c._id === client._id ? { ...client, isActive: true } : c,
            );
            break;
          case 'delete':
            state.listClientManagement = state.listClientManagement.filter(
              (c: TClientManagement) => c._id !== client._id,
            );
            break;
          case 'stop':
            state.listClientManagement = state.listClientManagement.map((c: TClientManagement) =>
              c._id === client._id ? { ...client, isActive: !client.isActive } : c,
            );
        }
      }
      if (clients) {
        switch (type) {
          case 'getList':
            const clientUser = getClientUser() ? JSON.parse(getClientUser()) : undefined;
            const currentUser = getUserFromCookie();
            const employeeClientId = currentUser?.employee
              ? currentUser?.employee?.clientId
              : undefined;
            const targetId = clientUser?._id || employeeClientId;
            const result = clients.find((item) => item._id === targetId);
            state.listClientManagement = result ? [result] : clients;
            break;
          case 'loadMore':
            state.listClientManagement = [...state.listClientManagement, ...clients];
            break;
        }
        state.totalPage = convertValueToNumber(totalPage);
        state.total = convertValueToNumber(total);
      }
    },
    setListFacilityManagement: (
      state,
      action: PayloadAction<{
        type: TSetListAction;
        facility?: TFacilityManagement;
        facilities?: TFacilityManagement[];
        page?: number;
        total?: number;
      }>,
    ) => {
      const { type, facility, facilities, total, page } = action.payload;
      if (facility) {
        switch (type) {
          case 'create':
            state.listFacilityManagement = [...state.listFacilityManagement, facility];
            break;
          case 'edit':
            state.listFacilityManagement = state.listFacilityManagement.map(
              (c: TClientManagement) => (c._id === facility._id ? facility : c),
            );
            break;
          case 'delete':
            state.listFacilityManagement = state.listFacilityManagement.filter(
              (c: TClientManagement) => c._id !== facility._id,
            );
            break;
          case 'stop':
            state.listFacilityManagement = state.listFacilityManagement.map(
              (c: TClientManagement) =>
                c._id === facility._id ? { ...facility, isActive: !facility.isActive } : c,
            );
        }
      }
      if (facilities) {
        switch (type) {
          case 'getList':
            const currentUser = getUserFromCookie();
            const employeeFacilityId =
              currentUser && currentUser.employee ? currentUser.employee.facilityId : undefined;
            const result = facilities.find((item) => item._id === employeeFacilityId);
            state.listFacilityManagement = result ? [result] : facilities;
            break;
          case 'loadMore':
            state.listFacilityManagement = [...state.listFacilityManagement, ...facilities];
            break;
        }

        state.pageFacility = page ?? DEFAULT_PAGE;
        state.totalFacility = convertValueToNumber(total);
      }
    },
    setListGroupManagement: (
      state,
      action: PayloadAction<{
        type: TSetListAction;
        group?: TGroupManagement;
        groups?: TGroupManagement[];
        page?: number;
        total?: number;
      }>,
    ) => {
      const { type, group, groups, total, page } = action.payload;
      if (group) {
        switch (type) {
          case 'create':
            state.listGroupManagement = [...state.listGroupManagement, group];
            break;

          case 'edit':
          case 'stop':
            state.listGroupManagement = state.listGroupManagement.map((c: TClientManagement) =>
              c._id === group._id ? group : c,
            );
            break;
          case 'delete':
            state.listGroupManagement = state.listGroupManagement.filter(
              (c: TClientManagement) => c._id !== group._id,
            );
            break;
        }
      }

      if (groups) {
        switch (type) {
          case 'getList':
            // const currentUser = getUserFromCookie();
            // const employeeFacilityId =
            //     currentUser && currentUser.employee ? currentUser.employee.facilityId : undefined;
            // const result = groups.find((item) => item._id === employeeFacilityId);
            // state.listFacilityManagement = result ? [result] : groups;
            state.listGroupManagement = groups;
            break;
          case 'loadMore':
            state.listGroupManagement = [...state.listGroupManagement, ...groups];
            break;
        }

        state.pageGroup = page ?? DEFAULT_PAGE;
        state.totalGroup = convertValueToNumber(total);
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetListFacility: (state) => {
      state.listFacilityManagement = [];
    },
    resetListGroup: (state) => {
      state.listGroupManagement = [];
    },
    resetListClient: (state) => {
      state.listClientManagement = [];
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPageFacility: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPageGroup: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setClientSelected: (state, action: PayloadAction<TClientManagement>) => {
      state.clientSelected = action.payload;
    },
    setEmailErr: (state, action: PayloadAction<string>) => {
      state.emailErr = action.payload;
    },
  },
});

export const { actions, reducer: ClientManagementReducer } = slice;
export const {
  setListClientManagement,
  setListFacilityManagement,
  setListGroupManagement,
  setLoading,
  resetListFacility,
  setClientSelected,
  setEmailErr,
  resetListClient,
  setPage,
  setPageFacility,
  setPageGroup,
} = actions;
export default ClientManagementReducer;
