import { ENameAlertChart } from 'app/enum';

export const listTimeSchedule = [
  '_0900_1000',
  '_0930_1030',
  '_1000_1100',
  '_1030_1130',
  '_1100_1200',
  '_1130_1230',
  '_1200_1300',
  '_1230_1330',
  '_1300_1400',
  '_1330_1430',
  '_1400_1500',
  '_1430_1530',
  '_1500_1600',
  '_1530_1630',
  '_1600_1700',
  '_1630_1730',
  '_1700_1800',
  '_1730_1830',
  '_1800_1900',
  '_1830_1930',
  '_1900_2000',
  '_1930_2030',
  '_2000_2100',
  '_2030_2130',
  '_2100_2200',
];

export const availableTimesCommon: string[] = [
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
];

export const statusSchedule = {
  NG: 'NG',
  AVAILABLE: '予約可能',
  IN_PROCESSING: '予約',
  CANCEL_AT_LAST: '直前キャンセル',
  FINISH: '完了',
  WAIT_FEEDBACK: 'FB未完了',
  RESERVATION: '予約中',
  FINISH_NOT_FEEDBACK: 'FB未完了',
};

export const listStatusSchedule = [
  'AVAILABLE',
  'RESERVATION',
  'CANCEL_AT_LAST',
  'FINISH',
  'WAIT_FEEDBACK',
  'CANCEL',
];

export const LISTCATEGORY = ['顕示発散', '環境受容', '自己実現', '探究論理'];

export const statusErrEmail = [6202, 1202, 3202, 9202, 7202, 5202, 6209, 1209, 4202];

export const dataAlertType = [
  {
    color: '#FF4218',
    label: ENameAlertChart.New,
  },
  {
    color: '#3CADFF',
    label: ENameAlertChart.Melancholy,
  },
  {
    color: '#84CC16',
    label: ENameAlertChart.Communication,
  },
  {
    color: '#CA8A04',
    label: ENameAlertChart.PowerHarassment,
  },
  {
    color: '#2563EB',
    label: ENameAlertChart.CarelessMistake,
  },
  {
    color: '#BE185D',
    label: ENameAlertChart.BreakageOfContract,
  },
  {
    color: '#C084FC',
    label: ENameAlertChart.TroubleMaker,
  },
  {
    color: '#0D9488',
    label: ENameAlertChart.DysthymicDisorder,
  },
];

export const dataAlert = {
  アラートN: '_',
  アラートM: '_',
  アラートCo: '_',
  アラートP: '_',
  アラートCa: '_',
  アラートB: '_',
  アラートT: '_',
  アラートD: '_',
  準アラートN: '_',
  準アラートM: '_',
  準アラートCo: '_',
  準アラートP: '_',
  準アラートCa: '_',
  準アラートB: '_',
  準アラートT: '_',
  準アラートD: '_',
};

export const listTextExplainChartEnergy = [
  {
    type: '事務職',
    value: '幅広い仕事への順応性、集団行動能力',
  },
  {
    type: '反復継続',
    value: '仕事への従順さ、保守的・安定感求める',
  },
  {
    type: '管理監督',
    value: '組織運営・管理能力の高さ',
  },
  {
    type: '企業開発',
    value: '敏感な性質と想像力、好奇心',
  },
  {
    type: '技術職',
    value: '問題意識、現状に満足しない、技術への適性',
  },
  {
    type: 'オペレーション',
    value: '与えられた仕事への粘り強さ、 敏感さ',
  },
  {
    type: '営業職',
    value: '仕事への意欲・根性',
  },
  {
    type: '接客対応',
    value: '仕事に変化を求め、それに適応できる臨概応変さ及び社交性',
  },
];

export const nameSystem = '運営事務局';

export const nameToHanas = 'toHANAS運営事務局';

export const messDefaultConsultant =
  'こんにちは\n' +
  'ここはtoHANAS運営事務局への直通 チャットです。\n' +
  'ご不明点やお困りごとがございましたら、お気軽にご連絡下さい。\n' +
  '※営業時間：平日9：00~18:00\n' +
  '※緊急時連絡先①：090-5348-8365 （担当：若林）\n' +
  '緊急時連絡先②：080-5546-0957 （担当：上松）\n';

export const MAX_COUNT_GROUP_SELECT_OF_EMPLOYEE = 2;
